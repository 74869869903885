.faqSection {
    background-image: url(../../Images/Background.webp);
    background-size: cover;
    background-repeat: no-repeat;
    height: 130vh;
    margin-top: 10rem;
    padding-bottom: 5rem;

    @media (max-width: $small) {
        height: 100%;
    }

    h2 {
        margin-top: 7rem;
    }

    .accordion {
        margin-left: auto;
        margin-right: auto;

        @media (max-width: $small),
        (min-width: $medium) {
            width: 100%;
        }

        @media (min-width: $large) {
            width: 50%;
        }
    }

    .accordion-item {
        background-color: transparent;
        border: none;

        &:last-child() .btn {
            padding-bottom: 1rem;
        }
    }

    .accordion-body {
        color: white;
        text-align: justify;
    }

    .accordion-button {
        background-color: transparent;
        color: white;
        border: none;

        &::after {
            background-image: url("../../Images/Plus.png");
        }
    }
}