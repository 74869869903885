.aboutSection {
    margin-top: 6rem;

    h3 {
        @media (max-width: $small) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    .btn {
        @media (max-width: $small) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            margin-bottom: 3rem;
        }
    }

    h2 {
        font-family: "Libre Caslon Text", Sans-serif;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1.2em;

        @media (max-width: $small) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            margin-bottom: 3rem;
        }
    }

    p {
        color: #6d6d6c;
        font-family: "Poppins", Sans-serif;
        font-size: 1rem;
        font-weight: normal;
        line-height: 3em;
        text-align: justify;

        @media (max-width: $small) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    span {
        background: -webkit-linear-gradient(#2e4bcd, #ba64e3);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .btn:hover {
        background: linear-gradient(#2e4bcd, #ba64e3);
    }

    img {
        @media (max-width: $small) {
            width: 100%;
            height: 15%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 3rem;
        }

        @media (min-width: $medium) {
            width: 92%;
            margin-left: auto;
            margin-right: auto;
        }

        @media (min-width: $large) {
            width: 76%;
            height: 25%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

#aboutMe {
    @media (max-width: $small) {
      display: grid;
    }
  }