html,
body {
    overflow-x: hidden;
}

body {
    font-family: "Poppins", sans-serif;
}

a {
    text-decoration: none;
}