.site-showcase {
    margin-top: 10rem;
    margin-bottom: 10rem;

    @media screen and (max-width: $small) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    h1 {
        color: #1d1e18;
        font-family: "Libre Caslon Text", Sans-serif;
        font-size: 3rem;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 1.2em;
        margin-top: 4rem;
        margin-bottom: 4rem;
        text-align: center;
    }

    span {
        background: -webkit-linear-gradient(#2e4bcd, #ba64e3);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 75%;

        @media screen and (max-width: $small) {
            width: 90%;
        }
    }

    button {
        margin-top: 3rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 25%;

        @media screen and (max-width: $small) {
            width: 50%;
        }
    }

    h2 {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: 2.7rem;
    }

    figcaption {
        text-align: center;
        margin-top: 2rem;
    }

    .project-description {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 75%;
        text-align: center;
        font-size: 1.1rem;
        line-height: 3rem;

        @media screen and (max-width: $small) {
            width: 90%;
            text-align: justify !important;
        }
    }

    .btn:hover {
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        background: linear-gradient(#2e4bcd, #ba64e3);
        border-color: #2e4bcd !important;
        color: white;
    }
}