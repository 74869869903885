.cookies,
.privacy-policy {
    margin-top: 13rem;
    margin-bottom: 5rem;

    h1 {
        padding-bottom: 3rem;
    }

    h2 {
        padding-top: 2rem;
    }

    span {
        margin-top: 6rem;
        font-weight: bold;
    }
}