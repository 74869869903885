.SEOSection {
    h2 {
        color: #1d1e18;
        font-family: "Libre Caslon Text", Sans-serif;
        font-size: 3rem;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 1.2em;
    }

    span {
        background: -webkit-linear-gradient(#062aca, #9401d9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        text-transform: none;
        text-align: center;
        font-size: 1.1rem;
        line-height: 3rem;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }

    .lighthouse-score,
    .responsiveness {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}