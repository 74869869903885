#hero {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../Images/Hero-Banner.webp);
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;

    @media (max-width: $small) {
        grid-auto-flow: row;
        background-size: cover;
        height: 100%;
    }

    img {
        border: 5px solid white;
        border-radius: 10px;
        width: 100%;

        @media (max-width: $small) {
            display: none;
            width: 100%;
            height: 25%;
        }
    }

    .btn {
        @media (max-width: $small) {
            margin-bottom: 2rem;
            font-size: 1rem;
        }
    }

    a {
        @media (max-width: $small) {
            color: white;
            text-decoration: none;
        }
    }

    h1 {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        text-transform: capitalize;
        background: -webkit-linear-gradient(#2e4bcd, #ba64e3);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-bottom: 1rem;
        font-size: 1.4rem;

        @media (max-width: $small) {
            text-align: center !important;
            font-size: 1.1rem;
        }

        @media (min-width: $medium) {
            text-align: inherit;
        }
    }

    h2 {
        font-family: "Libre Caslon Text", serif;
        line-height: 4rem;
        font-weight: bold;

        @media (max-width: $small) {
            text-align: center !important;
            font-size: 1.35rem;
        }

        @media (min-width: $medium) {
            font-size: 2rem;
        }

        @media (min-width: $large) {
            font-size: 2.8rem;
        }
    }

    .mobile-hero-image {
        @media (max-width: $small) {
            border: 5px solid white;
            border-radius: 10px;
            display: block;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 8rem;
            margin-bottom: 3rem;
        }

        @media (min-width: $medium) {
            display: none;
        }
    }

    .blurb {
        width: 80%;

        @media (max-width: $small) {
            width: 100%;
            margin-top: 1rem !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .adjust-margins {
        margin-left: auto;
        margin-right: auto;
    }

    #highlight-one {
        color: #fb7e85;
    }

    #highlight-two {
        background: -webkit-linear-gradient(#2e4bcd, #ba64e3);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.hero-button {
    @media (max-width: $small) {
        display: block;
        margin-top: 1rem !important;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: $medium) {
        width: 77%;
    }

    @media (min-width: $large) {
        width: 20vw;
    }

    @media (min-width: $xlarge) {
        width: 27%;
    }
}