.whatIDoSection {
    margin-top: 10rem;

    h2,
    h3 {
        text-align: center;
    }

    h2 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }

    h3 {
        color: #1d1e18;
        font-family: "Libre Caslon Text", Sans-serif;
        font-size: 3rem;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 1.2em;
        font-size: 2.5rem;
    }

    span,
    h2 {
        background: -webkit-linear-gradient(#062aca, #9401d9);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .listOne {
        margin-top: 4rem;
        text-transform: capitalize;

        li {
            font-size: 1.4rem;

            span {
                background: #fb7e85;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    .listTwo {
        padding-top: 1rem;

        li {
            font-size: 1.4rem;
            text-transform: capitalize;

            span {
                background: #fb7e85;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    #blurb {
        p {
            text-transform: none;
            text-align: center;
            font-size: 1.1rem;
            line-height: 3rem;
            width: 85%;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: $small) {
                width: 100%;
            }
        }
    }
}