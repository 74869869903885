.contact {
    padding-top: 13rem;
    padding-bottom: 10rem;
    background-color: #f8f8fa;
    display: grid;
    grid-template-columns: 50% 50%;

    @media (max-width: $small) {
        padding-top: 10rem;
        grid-template-columns: 100%;
        grid-template-rows: 60%;
    }

    h1 {
        color: #1d1e18;
        font-family: "Libre Caslon Text", Sans-serif;
        font-size: 3rem;
        font-weight: 600;
        text-transform: capitalize;

        @media (max-width: $small) {
            font-size: 2.5rem;
        }
    }

    span {
        background: -webkit-linear-gradient(#062aca, #9401d9);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        line-height: 3rem;
        padding-top: 1rem;

        margin-left: auto;
        margin-right: auto;

        @media (max-width: $small) {
            width: 100% !important;
            margin-left: auto;
            margin-right: auto;
            text-align: justify !important;
        }
    }

    .contact-button {
        margin-bottom: 1rem;
    }

    #responseMessage {
        display: none;
    }

    #contactSuccessMessage {
        display: none;
    }

    a {
        color: $primary;

        &:hover {
            color: #062aca;
        }
    }

    .mainForm {
        @media (max-width: $small) {
            width: 100% !important;
        }
    }
}