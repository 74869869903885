.btn-third {
    background-image: linear-gradient(260deg, #2e4bcd, #ba64e3);
    color: white;

    &:hover {
        background-color: transparent;
        background-image: linear-gradient(260deg, #354aa8, #b14be0);
        color: white;
    }
}

.fadeIn {
    animation: opac 2s;
}

@keyframes opac {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.moveFromTop {
    position: relative;
    animation: animatetop 0.4s;
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.moveFromLeft {
    position: relative;
    animation: animateleft 0.4s;
}

@keyframes animateleft {
    from {
        left: -300px;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}

.moveFromRight {
    position: relative;
    animation: animateright 0.4s;
}

@keyframes animateright {
    from {
        right: -300px;
        opacity: 0;
    }

    to {
        right: 0;
        opacity: 1;
    }
}

.moveFromBottom {
    position: relative;
    animation: animatebottom 2s;
}

@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0;
    }

    to {
        bottom: 0;
        opacity: 1;
    }
}

.zoom {
    animation: animatezoom 0.6s;
}

@keyframes animatezoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.arrowZoom {
    animation: animatezoom 6s;
}

@keyframes animatezoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.bg-primary {
    background-color: $primary  !important;
}

#gridDisplay {
    display: grid;
    grid-template-columns: 50% 50%;

    @media (max-width: $small) {
        grid-template-columns: 100%;
    }
}

.fa-github,
.fa-linkedin {
    &:hover {
        -webkit-transition: 0.7s ease-in-out;
        -o-transition: 0.7s ease-in-out;
        -moz-transition: 0.7s ease-in-out;
        transition: 0.7s ease-in-out;
        transform: scale(1.5);
    }
}

.accordion-header {
    button {
        text-transform: capitalize;
    }
}

#bluehostRecommendation {
    color: white;

    &:hover {
        font-weight: bold;
    }
}

.fa-6x {
    @media (max-width: $small) {
        font-size: 2em;
    }
}

.siteSpacer {
    width: 90%;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 7rem;
}

.cookiealert {
    position: fixed !important;

    &.show{
        color: white;
    }
}

.disclaimer {
    width: 100%;

    @media (min-width: 768px) {
        width: 75%;
    }
}

.fs-6 {
    font-size: 1.1rem !important;
}