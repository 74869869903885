.siteOffers {
    margin-top: 8rem;
    margin-bottom: 10rem;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;

    @media screen and (max-width: $small) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .introText {
        @media screen and (max-width: $small) {
            width: 100% !important;
        }
    }

    h1 {
        color: #1d1e18;
        font-family: "Libre Caslon Text", Sans-serif;
        font-size: 3rem;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 1.2em;
        text-align: center;
        margin-top: 5rem;
    }

    span {
        background: -webkit-linear-gradient(#062aca, #9401d9);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        font-family: "Poppins", Sans-serif;
        font-size: 1rem;
        font-weight: normal;
        line-height: 4em;
        width: 50%;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: $small),
        (min-width: $medium) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    .contactFormLink {
        text-decoration: none;
        color: black;
        font-weight: bold;
    }
}

.websiteSales {
    p {
        font-family: "Poppins", Sans-serif;
        font-size: 1rem;
        font-weight: normal;
        line-height: 4em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3rem;

        @media (max-width: $small) {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    .websitesOnOffer {
        display: grid;
        grid-auto-flow: column;
        gap: 10rem;

        @media (max-width: $small) {
            grid-auto-flow: row;
            gap: 0;
        }
    }

    .websiteDescription {
        h2 {
            color: #1d1e18;
            font-family: "Libre Caslon Text", Sans-serif;
            font-size: 3rem;
            font-weight: 600;
            text-transform: capitalize;
            line-height: 1.2em;
            text-align: left;
            margin-bottom: 3rem;

            @media screen and (max-width: $small) {
                text-align: center;
            }
        }

        p {
            text-align: justify;

            @media screen and (max-width: $small) {
                text-align: center;
            }
        }
    }

    .btn {
        @media screen and (max-width: $small) {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}