.portfolioSection {
    background-color: #f8f8fa;
    margin-top: 5rem;
    padding-bottom: 3rem;

    h2 {
        color: #1d1e18;
        font-family: "Libre Caslon Text", Sans-serif;
        font-size: 3rem;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 1.2em;
        text-align: center;
        margin-top: 5rem;
    }

    span {
        background: -webkit-linear-gradient(#062aca, #9401d9);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #6d6d6c;
        font-family: "Poppins", Sans-serif;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.5em;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8rem;

        @media (max-width: $small),
        (min-width: $medium) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    img {
        box-shadow: 5px 6px 10px rgba(0, 0, 0, 0.4);

        @media (max-width: $small),
        (min-width: $medium) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 3rem;
        }

        @media (min-width: $large) {
            margin-bottom: 5rem;
        }
    }

    .portfolioRowOne {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;

        @media (max-width: $small) {
            grid-auto-flow: row;
        }
    }

    a {
        position: relative;
        width: 100%;
        height: 100%;
    }

    img {
        filter: brightness(0.4);
    }

    .overlayOne {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 1;
        transition: 0.5s ease;
        background-color: transparent;
    }

    .overlayTwo {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 10%;
        opacity: 0;
        transition: 0.5s ease;
        background-color: transparent;
    }

    a:hover {
        .overlayOne {
            -webkit-transition: 0.7s ease-in-out;
            -o-transition: 0.7s ease-in-out;
            -moz-transition: 0.7s ease-in-out;
            transition: 0.7s ease-in-out;
            opacity: 0;
        }

        .overlayTwo {
            -webkit-transition: 0.7s ease-in-out;
            -o-transition: 0.7s ease-in-out;
            -moz-transition: 0.7s ease-in-out;
            transition: 0.7s ease-in-out;
            opacity: 1;
        }

        img {
            -webkit-transition: 0.7s ease-in-out;
            -o-transition: 0.7s ease-in-out;
            -moz-transition: 0.7s ease-in-out;
            transition: 0.7s ease-in-out;
            transform: scale(1.03);
            filter: brightness(0.25);
        }
    }

    .text {
        color: white;
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;

        p {
            color: rgba(255, 255, 255, 0.99);
            width: fit-content;
            font-family: "Libre Caslon Text", Sans-serif;
        }
    }
}