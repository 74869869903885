.footerLogo {
    display: block;

    @media (max-width: $small) {
        width: 50%;
        height: 25%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
    }
}

.siteFooter {
    background-image: url(../../Images/Background.webp);
    background-size: cover;
    background-repeat: no-repeat;
    height: 50%;

    @media (max-width: $small) {
        height: 100%;
    }

    a {
        text-decoration: none;

        &:hover {
            -webkit-transition: 0.4s ease-in-out, -webkit-transform 0.2s ease-in-out;
            -o-transition: 0.4s ease-in-out, -webkit-transform 0.2s ease-in-out;
            -moz-transition: 0.4s ease-in-out, -webkit-transform 0.2s ease-in-out;
            transition: 0.4s ease-in-out, -webkit-transform 0.2s ease-in-out;
            text-shadow: 1px 1px 20px white;
            text-decoration: none;
        }
    }

    .footer-text {
        @media (max-width: $small) {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .footer-title {
        font-size: 1.3rem;
    }
}