.navbar {
    a {
        text-decoration: none;
        color: white;

        &:hover {
            text-decoration: none;
            color: none;
        }
    }

    h1 {
        font-size: 1.8rem;
    }
}

#headerLink {
    @media (max-width: $small) {
        display: contents;
    }
}

.navbar-nav {
    @media (max-width: $small) {
        margin-top: 1rem;
    }
}

.nav-link {
    padding-left: 1.5rem !important;
}

.desktopAndTabletLogo {
    width: 100%;
    height: 100%;
    display: block;

    @media (max-width: $small) {
        width: 50%;
        height: 100%;
    }
}

.navbar-collapse {
    @media (max-width: $small) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top: 5rem;
        overflow-y: auto;
        visibility: hidden;
        background-color: $primary;
        z-index: -1;
        -webkit-transition: 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
        -moz-transition: 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
        transition: visibility 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;

        &.show {
            visibility: visible;
            transform: translateX(-100%);
        }

        @-webkit-keyframes slide {
            100% {
                left: 0;
            }
        }

        @keyframes slide {
            100% {
                left: 0;
            }
        }

        .nav-item {
            &:nth-child(6) a {
                border-bottom: none;
                text-align: center;
                border: 1px solid;
                margin-top: 2rem;
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                border-radius: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                background-color: white;

                &:hover {
                    text-decoration: none !important;
                }
            }
        }

        .nav-link {
            padding-top: 1rem;
            padding-bottom: 1rem;

            &:hover {
                text-decoration: none !important;
            }
        }
    }
}